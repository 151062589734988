import React from 'react';
import { map, sortBy } from 'lodash';
import {
  Definitions,
  Dispatcher,
  Store,
} from 'q3-admin/lib/containers';
import { Link } from '@reach/router';
import useRest from 'q3-ui-rest';
import { castToUTC } from 'q3-ui-forms/lib/helpers';
import { string } from 'q3-ui-helpers';
import Repeater from 'q3-ui-repeater';
import {
  Link as MuiLink,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import { useTranslation } from 'q3-ui-locale';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import PropTypes from 'prop-types';
import CoursesUsersInvite from '../CoursesUsersInvite';
import SecondarySearchList from '../SecondarySearchList';
import useSecondaryListStateHelpers from '../useSecondaryListStateHelpers';
import CoursesUsersReinvite from '../CoursesUsersReinvite';

const CoursesUsers = ({
  includeStartColumn,
  singular,
  plural,
  ...etc
}) => {
  const { data } = React.useContext(Store);
  const { replace, poll } = React.useContext(Dispatcher);
  const { collectionName, id, resourceNameSingular } =
    React.useContext(Definitions);

  const subdocumentState = useRest({
    key: singular,
    pluralized: plural,
    runOnInit: false,
    url: `/${collectionName}/${id}/${plural}`,
    sendUpdateAsFullReceipt: true,

    poll: ({ full }) =>
      Promise.resolve(
        replace({
          [resourceNameSingular]: full,
        }),
      ),
  });

  const { t } = useTranslation('labels');
  const { add, remove } = useSecondaryListStateHelpers(
    subdocumentState,
    singular,
    data[plural],
  );

  return (
    <Repeater
      {...etc}
      id={id}
      disableEditor
      collectionName={collectionName}
      edit={subdocumentState.patch}
      editBulk={subdocumentState.patchBulk}
      create={subdocumentState.post}
      remove={subdocumentState.remove}
      removeBulk={subdocumentState.removeBulk}
      data={sortBy(data[plural], [
        `${singular}.firstName`,
        `${singular}.lastName`,
        'email',
      ])}
      groupBy={[
        {
          label: 'Invited',
          fn: (arg) => !arg[singular],
        },
        {
          label: 'Registered',
          fn: (arg) => arg[singular],
        },
      ]}
      th="name"
      root={plural}
      name={plural}
      initialValues={{
        ids: map(data[plural], `${singular}.id`),
      }}
      cardProps={{
        title: (v) =>
          v[singular]
            ? string.makeName(v[singular])
            : 'Anonymous ',
        // eslint-disable-next-line
        describe: ({ email, ...rest }) => {
          const user = rest[singular];
          const v = user?.email || email;
          const tel = user?.tel;

          return (
            <span>
              <MuiLink color="inherit" href={`mailTo:${v}`}>
                {v}
              </MuiLink>
              {tel && (
                <>
                  <br />
                  <MuiLink
                    color="inherit"
                    href={`tel:${tel}`}
                  >
                    {tel}
                  </MuiLink>
                </>
              )}
            </span>
          );
        },
        attributes: [
          includeStartColumn ? 'start' : null,
          `${singular}.lastLoggedIn`,
          `${singular}.numberOfJournals`,
        ].filter(Boolean),
        editable: {
          [`${singular}.lastLoggedIn`]: {
            disabled: true,
            toDate: true,
            type: 'date',
          },
          'start': {
            toDate: true,
            type: 'date',
            marshal: {
              start: [castToUTC],
            },
          },
        },
        actions: [
          {
            label: 'profile',
            disableDialog: true,
            // eslint-disable-next-line
            component: ({ row = {} }) =>
              row[singular] ? (
                <Tooltip title={t('profile')}>
                  <IconButton
                    color="inherit"
                    component={Link}
                    to={`/app/users/${row[singular].id}`}
                  >
                    <OpenInBrowserIcon />
                  </IconButton>
                </Tooltip>
              ) : null,
          },
          {
            label: 'profile',
            disableDialog: true,
            // eslint-disable-next-line
            component: ({ row }) =>
              row[singular] ? null : (
                <CoursesUsersReinvite
                  course={id}
                  id={row.id}
                  parent={plural}
                  email={row.email}
                />
              ),
          },
        ],
      }}
      renderOther={() =>
        etc?.disableAdd ? null : (
          <CoursesUsersInvite
            course={id}
            parent={plural}
            poll={poll}
          />
        )
      }
    >
      <SecondarySearchList
        key="user"
        pluralized="users"
        onClick={add}
        onRemove={remove}
        query={
          singular === 'coach'
            ? '?sort=name&role=in(Administrator,Coach,Master Administrator)'
            : '?sort=name'
        }
        url="users"
        makeListItemTextProps={(item) => ({
          primary: item.name,
          secondary: item.role,
        })}
      />
    </Repeater>
  );
};

CoursesUsers.defaultProps = {
  includeStartColumn: false,
};

CoursesUsers.propTypes = {
  includeStartColumn: PropTypes.bool,
  plural: PropTypes.oneOf(['coaches', 'participants'])
    .isRequired,
  singular: PropTypes.oneOf(['coach', 'participant'])
    .isRequired,
};

export default CoursesUsers;
